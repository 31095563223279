var bookingSource = {
  run: function () {
    this.fetchData();
  },

  fetchData: function () {
    var companyId = $("#booking-sources-tile").data('company-id');
    var startDate = $("#booking-sources-tile").data('start-date');
    var endDate = $("#booking-sources-tile").data('end-date');

    var currentUrl = new URL(window.location.href)
    var bookingSourcesUrl = new URL(
      window.location.protocol + "//" +
      window.location.host +
      "/reports/booking_sources.json")

    this.copySearchParams(currentUrl, bookingSourcesUrl)

    bookingSourcesUrl.searchParams.set("company_id", companyId)
    bookingSourcesUrl.searchParams.set("start_date", startDate)
    bookingSourcesUrl.searchParams.set("end_date", endDate)

    if (companyId) {
      $.ajax({
        url: bookingSourcesUrl.href
      }).done((data) => {
        if (this.dataIsEmpty(data)) {
          this.renderEmptyState();
        } else {
          this.buildChart(this.formatData(data));
        }
      }).fail(function(err) {
        console.log(err);
      });
    }
  },

  copySearchParams: function (sourceUrl, destUrl) {
    for(var pair of sourceUrl.searchParams.entries()) {
      destUrl.searchParams.set(pair[0], pair[1])
    }
  },

  dataIsEmpty: function (data) {
    if (data.length == 0) {
      return true
    }
    for(var datum of data) {
      if (datum.spend_in_cents > 0) {
        return false
      }
    }
    return true
  },

  renderEmptyState: function () {
    $("#booking-sources-tile").append("\
    <div class='tile-empty-state'>\
      <div>\
        <i class='fas fa-dollar-sign mr1 tile-empty-state-icon'></i>\
      </div>\
      <div>\
        No booking sources matched the applied filter\
      </div>\
    </div>\
    ");
  },

  buildOptions: function () {
    return {
      maintainAspectRatio: false,
      cutoutPercentage: 80,
      plugins: {
        tooltip: {
          callbacks: {
              label: function(context) {
                return ' $' + context.formattedValue;
              }
            }
          },
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              fontSize: 11
            }
          }
      }
    }
  },

  formatData: function (data) {
    let chartData = {
      datasets: [{
        data: $.map(data, function(datum) { return datum.spend_in_cents }),
        backgroundColor: [
          window.chartColors.green,
          window.chartColors.blue,
          window.chartColors.red,
          window.chartColors.orange,
          window.chartColors.deepRed,
        ]
      }],
      labels: $.map(data, function(datum) { return datum.booking_source_name + '  (' + datum.bs_average + '%)' })
    };
    return chartData;
  },

  buildChart: function (data) {
    $("#booking-sources-tile").append("<canvas></canvas>");
    var ctx = $("#booking-sources-tile canvas")[0].getContext('2d');
    var chart = new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: this.buildOptions()
    });
    chart.canvas.parentNode.style.height = '183px';
  }
}

$("#booking-sources-tile").ready(function() {
  bookingSource.run();
})
