$(document).ready(function () {
  function setError(attribute, value) {
    document.getElementById(`error_${attribute}_program_goal_details`).innerHTML = value
  }

  const createProgramGoalDetails = function (e) {
    e.preventDefault();

    let target = e.target;
    if(target.dataset.attributeName == undefined)
    {
      target = target.parentElement
    }
    target.setAttribute('data-updated', false)
    const attributeName = target.dataset.attributeName
    const attribute = target.dataset.attributeName.slice(0, target.dataset.attributeName.length-3)
    setError(attribute, '');
    const companyId = target.dataset.companyId
    const domainId = target.dataset.domainId

    data = {
      program_detail_goal: {
        company_id: companyId,
      }
    }

    if (domainId) data.program_detail_goal.domain_id = domainId
    data.program_detail_goal[attributeName] = $(`#program_detail_goal_${attributeName}`).val()
    if (!data.program_detail_goal[attributeName]) {
      return;
    }

    function addNewProgramDetail(response) {
      let new_line = $(`.${attribute}_line_example`).clone();
      new_line.find(`.${attribute}_name`)[0].innerHTML = response.name;
      new_line.removeAttr("style")
      new_line.removeAttr("class")
      new_line[0].classList.add("program_detail_goal")
      let program_detail_goal_percentage = new_line.find('#program_detail_goal_percentage')[0]
      program_detail_goal_percentage.dataset.id = response.id;
      program_detail_goal_percentage.addEventListener('input', updateProgramDetailsGoal);
      destroy_program_detail_goal = new_line.find('.destroy_program_detail_goal')[0]
      new_line[0].dataset.id = response.id
      destroy_program_detail_goal.dataset.id = response.id;
      destroy_program_detail_goal.addEventListener('click', destroyProgramDetailsGoal);

      $(`#${attribute}-benchmarks`).append(new_line)
    }
    fetch(`/companies/${companyId}/program_detail_goals`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(response => response.json()).then((response) => {
      target.setAttribute('data-updated', true)
      if (response.errors) {
        setError(attribute, response.errors)
      } else {
        addNewProgramDetail(response)
      }
    })
  }

  let addLine = document.getElementById("add-booking-source-line-button")
  if (addLine)
  {
    addLine.addEventListener('click', createProgramGoalDetails);
  }

  addLine = document.getElementById("add-supplier-line-button")
  if (addLine)
  {
    addLine.addEventListener('click', createProgramGoalDetails);
  }

  let timeout = null;
  function updateProgramDetailsGoal(e) {
    e.preventDefault();
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      let target = e.target;
      target.setAttribute('data-updated', false)
      let data = {
        program_detail_goal: {
          percentage: target.value,
        }
      }
      fetch(`/companies/${target.dataset.companyId}/program_detail_goals/${target.dataset.id}`, {
        method: "PUT",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(response => response.json()).then(() => {
        target.setAttribute('data-updated', true)
      })
    }, 500);
  }

  let programDetailGoalsInputs = $("input.program_detail_goal_percentage")
  Array.from(programDetailGoalsInputs).forEach(function (element) {
    element.addEventListener('input', updateProgramDetailsGoal);
  });

  function destroyProgramDetailsGoal(e) {
    let target = e.target;
    target.setAttribute('data-updated', false)
    $(`.program_detail_goal[data-id='${target.dataset.id}']`)[0].style.display = "none";
    let data = {
      program_detail_goal: {
        id: target.dataset.id,
      }
    }
    fetch(`/companies/${target.dataset.companyId}/program_detail_goals/${target.dataset.id}`, {
      method: "DELETE",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(response => response.json()).then(() => {
      target.setAttribute('data-updated', true)
    })
  }

  let destroyDetail = document.getElementsByClassName("destroy_program_detail_goal")
  Array.from(destroyDetail).forEach(function (element) {
    element.addEventListener('click', destroyProgramDetailsGoal);
  });
});
