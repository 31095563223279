$(document).on("change", "[name*='email_forward_type']", function () {
  updateFieldDisplay(this, "email_forward_type");
});

$(document).on("change", "[name*='email_retrieval_type']", function () {
  updateFieldDisplay(this, "email_retrieval_type");
});

$(document).ready(function () {
  let forwardRulesSelector = $(this).find("[name*='email_retrieval_type']");
  if (forwardRulesSelector.length >= 0) {
    updateFieldDisplay(forwardRulesSelector[0], "email_retrieval_type");
  }
});

function updateFieldDisplay(control, controlName) {
  let type = $(control).val();
  const panel = $(control).closest(".card-body");
  if (type == "forward_microsoft_graph_user") type = "forward_microsoft_graph_service";
  if (type == "forward_gmail_by_user") type = "forward_gmail_by_service"; // gmail_service and gmail_oauth have the same form
  if (type === "retrieve_microsoft_graph_user") type = "retrieve_microsoft_graph_service";
  $(panel)
    .children(".form-group")
    .each(function () {
      if (!$(this).hasClass("company_domains_" + controlName)) {
        if (!$(this).hasClass("always-show")) {
          $(this).addClass("hidden");
        }
      }
//      $(this)
//        .find("input[name*='domain_auths'],select[name*='domain_auths']")
//        .each(function () {
//          $(this).val("");
//        });
    });
  $(panel)
    .find("#" + type)
    .removeClass("hidden");
  if (type == 'retrieve_gmail_by_service' || type == 'retrieve_gmail_by_user') {
    $(panel)
    .find("#retrieve_gmail_all")
    .removeClass("hidden");
  }
  else {
    $(panel)
    .find("#retrieve_gmail_all")
    .addClass("hidden");
  }
}
