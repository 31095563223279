import $ from "jquery";
$(function () {
  $("#import-email-from-clipboard").click(function () {
    if (!$("#import-email-from-clipboard").attr("disabled")) {
      importEmailFromClipboard();
      disableEmailImportButton();
    }
  });

  $("#admin-not-business-travel-btn").click(function () {
    console.log("clicked");
    toggleNotBusinessTravelAdmin(this, $(this).data().id);
  });

  $("#not-business-travel-btn").click(function () {
    console.log("clicked");
    toggleNotBusinessTravel(this, $(this).data().id);
  });
});

function disableEmailImportButton() {
  $("#import-email-from-clipboard").text("Importing...");
  $("#import-email-from-clipboard").attr("disabled", true);
}

function enableEmailImportButton() {
  $("#import-email-from-clipboard").text("Import Email from Clipboard");
  $("#import-email-from-clipboard").removeAttr("disabled");
}

function importEmailFromClipboard() {
  navigator.clipboard
    .readText()
    .then(function (emailJSON) {
      postEmail(emailJSON);
    })
    .catch(function (err) {
      alert(
        "Failed to read clipboard contents. Perhaps you need to grant access?", err
      );
    });
}

function toggleNotBusinessTravel(ele, id) {
  fetch("/travels/"+id+"/toggle_not_business_travel", {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => response.json())
  .then(function(data) {
    console.log(data);
    if(data) {
      $(ele).addClass("marked").blur();
    } else {
      $(ele).removeClass("marked").blur();
    }
  });
}

function toggleNotBusinessTravelAdmin(ele, id) {
  fetch("/admin/emails/"+id+"/toggle_not_business_travel", {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => response.json())
  .then(function(data) {
    console.log(data);
    if(data) {
      $(ele).addClass("marked").blur();
    } else {
      $(ele).removeClass("marked").blur();
    }
  });
}

function postEmail(emailJSON) {
  let always = function () {
    enableEmailImportButton();
  };

  fetch("/admin/emails", {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: { json: emailJSON } }), // body data type must match "Content-Type" header
  })
  .then((response) => response.json())
  .then(function (data) {
    let emailId = data.id;
    window.location.href = window.location.protocol + "//" + window.location.host + "/admin/emails/" + emailId;
  })
  .catch((err) => {
    alert("Error: email was not imported.\n\n" + err);
  })
  .then(always, always);
}
