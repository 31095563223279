$(document).ready(function () {
  let timeout = null;
  const updateProgramGoal = function (e) {
    e.preventDefault();
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      let target = e.target;
      target.setAttribute('data-updated', false)
      let data = {
        program_goal: {
          domain_id: target.dataset.domainId,
          travel_type: target.dataset.travelType,
        }
      }
      data.program_goal[target.dataset.attributeName] = target.value
      fetch('', {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(response => response.json()).then(() => {
        target.setAttribute('data-updated', true)
      })
    }, 500);
  }

  let programGoalsInputs = $("input.program_goal")
  Array.from(programGoalsInputs).forEach(function (element) {
    element.addEventListener('input', updateProgramGoal);
  });

  function calculatePrecisionColors() {
    let budgets = $(".budgets-js")
    function numberWithPrecision(number, precision) {
      let digits = number.toString().length
      let multiplier = Math.pow(10, digits - precision)
      return (number / multiplier) * multiplier
    }
    Array.from(budgets).forEach(function (element) {
      const sigFig = 2;
      const predictedJs = element.getElementsByClassName('predicted-js')[0]

      const programGoalValue = element.getElementsByClassName('program_goal')[0].value;
      const predictionValue = predictedJs.dataset.value;
      let budget = numberWithPrecision(parseInt(programGoalValue * 1000000), sigFig);
      let predicted = numberWithPrecision(parseInt(predictionValue), sigFig);
      if (budget < predicted) {
        predictedJs.className = "predicted-js text-danger"
      } else if (budget > predicted) {
        predictedJs.className = "predicted-js text-success"
      } else {
        predictedJs.className = "predicted-js"
      }
    });
  }

  let programGoalsBudgetInputs = $("input.program_goal_budget")
  Array.from(programGoalsBudgetInputs).forEach(function (element) {
    element.addEventListener('input', calculatePrecisionColors);
  });

  calculatePrecisionColors();

  var $picker = $("#program-goals-date-range-picker")

  if ($picker.length != 0) {
    var date_format = "MMM DD, YYYY";
    var startDate = $picker.data("start-date");
    var endDate = $picker.data("end-date");
    $picker.daterangepicker({
      "timePicker": false,
      "linkedCalendars": false,
      "startDate": startDate,
      "endDate": endDate,
      locale: {
        format: date_format
      }
    }, function (start, end) {
      $picker[0].setAttribute('data-updated', false)
      let data = {
        program_goal: {
          start_date: start,
          end_date: end,
        }
      }
      fetch('', {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(response => response.json()).then((response) => {
        $picker[0].setAttribute('data-updated', true)
        Array.from(response.meta.current_spends).forEach(function (spend) {
          let element = document.getElementById(`${spend.travel_type}_predicted`)
          element.innerHTML = spend.pretty_predicted
          element.dataset.value = spend.predicted

          element = document.getElementById(`${spend.travel_type}_current`)
          element.innerHTML = spend.pretty_current

          element = document.getElementById(`date-range`)
          let startDate = start.format(date_format)
          let endDate = end.format(date_format)
          element.innerHTML = `${startDate} - ${endDate}`
        });
        calculatePrecisionColors();
      })
    });
  }
});
