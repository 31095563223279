import mapboxgl from 'mapbox-gl'

$(document).ready(function() {
  if ($("#travelers-map").length != 1) {
    return;
  }
  $.ajax({
    url: "/travelers/locate.geojson"
  })
    .done(function(data) {
      populateMap(data);
    })
    .fail(function(err) {
      console.error("err", err);
    });
});

function populateMap(geojson) {
  mapboxgl.accessToken = $("#travelers-map").data("mapboxgl-access-token")

  var map = new mapboxgl.Map({
    container: "travelers-map",
    style: "mapbox://styles/mapbox/light-v9",
    zoom: 1,
    center: [0, 30]
  });

  map.on("load", function() {
    addDataToMap(map, geojson);

    drawClusters(map);
    drawClusterNumbers(map);
    drawTravelerBackgrounds(map);
    drawTravelerIcons(map, geojson);

    handleClusterClicks(map);
    handleTravelerClicks(map);
  });
}

function drawClusters(map) {
  map.addLayer({
    id: "clusters",
    type: "circle",
    source: "travelers",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": "#0295EE",
      "circle-radius": 10,
      "circle-stroke-width": 4,
      "circle-stroke-color": "rgba(2, 149, 238, 0.5)"
    }
  });
}

function drawClusterNumbers(map) {
  map.addLayer({
    id: "cluster-count",
    type: "symbol",
    source: "travelers",
    filter: ["has", "point_count"],
    paint: {
      "text-color": "#FFF"
    },
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": [
        "Roboto Black",
        "DIN Offc Pro Medium",
        "Arial Unicode MS Bold"
      ],
      "text-size": 12,
      "text-offset": [0, 0.15]
    }
  });
}

function drawTravelerBackgrounds(map) {
  map.addLayer({
    id: "unclustered-point-background",
    type: "circle",
    source: "travelers",
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-color": "#0295EE",
      "circle-radius": 10,
      "circle-stroke-width": 4,
      "circle-stroke-color": "rgba(2, 149, 238, 0.5)"
    }
  });
}

function drawTravelerIcons(map, geojson) {
  var featuresCount = geojson.features.length;
  for (var i = 0; i < featuresCount; i++) {
    var feature = geojson.features[i];
    var id = feature.properties.id;
    var icon = '/traveler-icon.png'
    map.loadImage(icon, generateAddImageFunction(map, id));
  }
}

function generateAddImageFunction(map, id) {
  return function(error, image) {
    map.addImage(id, image);
    map.addLayer({
      id: "unclustered-point-" + id,
      type: "symbol",
      source: "travelers",
      filter: ["all", ["!has", "point_count"], ["==", "id", id]],
      layout: {
        "icon-image": id
      }
    });
  };
}

function addDataToMap(map, geojson) {
  map.addSource("travelers", {
    type: "geojson",
    data: geojson,
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 50
  });
}

function handleClusterClicks(map) {
  map.on("click", "clusters", function(e) {
    var features = map.queryRenderedFeatures(e.point, { layers: ["clusters"] });
    var clusterId = features[0].properties.cluster_id;
    map
      .getSource("travelers")
      .getClusterExpansionZoom(clusterId, function(err, zoom) {
        if (err) return;

        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom
        });
      });
  });
  map.on("mouseenter", "clusters", function() {
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", "clusters", function() {
    map.getCanvas().style.cursor = "";
  });
}

function handleTravelerClicks(map) {
  map.on("click", "unclustered-point-background", function(e) {
    var coordinates = e.features[0].geometry.coordinates.slice();
    var description = e.features[0].properties.description;

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map);

    var features = map.queryRenderedFeatures(e.point, {
      layers: ["unclustered-point-background"]
    });
    //travelerId = features[0].properties.id;
  });

  map.on("mouseenter", "unclustered-point-background", function() {
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", "unclustered-point-background", function() {
    map.getCanvas().style.cursor = "";
  });
}
