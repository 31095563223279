$(document).ready(function() {
  $(".matchable-select").each(function() {
    $this = $(this)
    if ($this.hasClass('selectized')) {
      return
    }
    var updatePath = $this.data("update-path")
    $this.selectize({
      preload: true,
      load: searchRequest(),
      onChange: generateOnChangeHandler(updatePath)
    })
  })
})

window.searchRequestCache = {}
window.queuedSearchCallbacks = {}

function searchRequest() {
  var searchURLBuilder = generateSearchURLBuilder($this)
  return function(query, callback) {
    if (cacheHasKey(query)) {
      useCachedResponse(query, callback)
    } else {
      cacheResponse(query, "pending")
      var searchURL = searchURLBuilder(query)
      performSearch(searchURL, query, callback)
    }
  }
}

function performSearch(searchURL, query, callback) {
  $.ajax({
    url: searchURL,
    type: 'GET',
    error: function(jqXHR, textStatus, errorThrown) {
      console.error(jqXHR)
      console.error(textStatus)
      console.error(errorThrown)
      callback()
    },
    success: function(response) {
      cacheResponse(query, response)
      callback(response)
      workSearchCallbackQueue(query, response)
    }
  })
}

function workSearchCallbackQueue(query, response) {
  if (typeof queuedSearchCallbacks[query] !== 'undefined') {
    while (queuedSearchCallbacks[query].length > 0) {
      queuedSearchCallbacks[query].pop()(response)
    }
  }
}

function queueSearchCallback(query, callback) {
  if (typeof queuedSearchCallbacks[query] === 'undefined') {
    queuedSearchCallbacks[query] = []
  }
  queuedSearchCallbacks[query].push(callback)
}

function cacheHasKey(query) {
  return !!window.searchRequestCache[query]
}

function cacheResponse(query, response) {
  window.searchRequestCache[query] = response
}

function useCachedResponse(query, callback) {
  if (window.searchRequestCache[query]) {
    if (window.searchRequestCache[query] == "pending") {
      queueSearchCallback(query, callback)
    } else {
      callback(window.searchRequestCache[query])
    }
  }
}

function generateSearchURLBuilder($element) {
  var searchPath = $element.data("search-path")
  var companyId = $element.data("company-id")
  var domainId = $element.data("domain-id")
  var searchURL = new URL(
    window.location.protocol + "//" +
    window.location.host +
    searchPath)
  if (typeof(companyId) !== 'undefined') {
    searchURL.searchParams.set("company_id", companyId)
  }
  if (typeof(domainId) !== 'undefined') {
    searchURL.searchParams.set("domain_id", domainId)
  }
  return function(query) {
    searchURL.searchParams.set("q", query)
    return searchURL.href
  }
}

function generateOnChangeHandler(updateUrl) {
  return function(value) {
    $.ajax({
      url: updateUrl,
      type: 'PATCH',
      data: {
        matching_lookup: {
          matchable_id: value
        }
      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.error(jqXHR)
        console.error(textStatus)
        console.error(errorThrown)
      }
    })
  }
}
