import $ from 'jquery'
$(function () {
  let Rubulex = {}

  Rubulex.queryRegex = function (e) {
    if (this.inputTimeout) {
      window.clearTimeout(this.inputTimeout)
      this.inputTimeout = null
    }
    if (Rubulex.regexInput.value !== "" && Rubulex.testData.value !== "") {
      this.inputTimeout = window.setTimeout(function () {
        let body = {
          regex: Rubulex.regexInput.value,
          options: Rubulex.regexOptionsInput.value,
          test_data: Rubulex.testData.value,
        }
        fetch('regexes/api', {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body), // body data type must match "Content-Type" header
        })
          .then(response => response.json())
          .then(function (json_response) {
            Rubulex.result.innerHTML = json_response.match_result
            if (json_response.match_groups !== null) {
              Rubulex.result_group_container.style.display = "block"
              Rubulex.result_groups.innerHTML = json_response.match_groups
              Rubulex.js_test_data_input.value = json_response.match_group_data
            } else {
              Rubulex.result_group_container.style.display = "none"
            }
          });
      }, 750)
    }
  }
  Rubulex.regexInput = document.getElementById("regex")
  Rubulex.js_test_data_input = document.getElementById("js_test_data_input")
  Rubulex.regexOptionsInput = document.getElementById("regex_options")
  Rubulex.testData = document.getElementById("test_data")
  Rubulex.result = document.getElementById("result")
  Rubulex.result_group_container = document.getElementsByClassName("result_groups")[0]
  Rubulex.result_groups = document.getElementById("result_groups")
  var elements = [
    Rubulex.regexInput,
    Rubulex.regexOptionsInput,
    Rubulex.testData
  ]

  if (Rubulex.regexOptionsInput) {
    Rubulex.queryRegex();
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener('input', Rubulex.queryRegex, false)
    }
  }
})