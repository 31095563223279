import $ from "jquery";
$(function() {
  let timeout = null;
  const updatePolicy = function (e) {
    e.preventDefault();
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      let target = e.target;
      target.setAttribute('data-updated', false)
      let attributeModel = target.dataset.attributeModel;
      let otherAttributes = target.dataset.otherAttributes;
      let attributeName = target.name;
      let data = {}
      if (otherAttributes) {
        data[attributeModel] = JSON.parse(otherAttributes);
      }
      else {
        data[attributeModel] = {}
      }

      data[attributeModel][attributeName] = target.value;

      fetch(target.dataset.url, {
        method: target.dataset.httpMethod || "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify(data),
      }).then(() => {
        target.setAttribute('data-updated', true)
      })
    }, 500);
  }

  let captravePolicyValueInputs = $("input.auto_update_input-js")
  Array.from(captravePolicyValueInputs).forEach(function (element) {
    element.addEventListener('input', updatePolicy);
  });

  captravePolicyValueInputs = $("select.auto_update_input-js")
  Array.from(captravePolicyValueInputs).forEach(function (element) {
    element.addEventListener('change', updatePolicy);
  });
});
