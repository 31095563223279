$(document).on("click", "[data-remove-action]", function(event) {
  event.preventDefault();
  $(this).prev("input[type=hidden]").val("1");
  return $(this).closest("[data-hide-on-destroy]").hide();
});

$(document).on("click", "[data-add-action]", function(event) {
  var regexp, time;
  event.preventDefault();
  time = new Date().getTime();
  regexp = new RegExp($(this).data("id"), "g");
  return $(this).before($(this).data("fields").replace(regexp, time));
});

// ---
// generated by coffee-script 1.9.2
