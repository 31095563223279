/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable";
import "regenerator-runtime/runtime";

global.jQuery = require("jquery");
global.$ = require("jquery");
require("jquery-ujs");
require("bootstrap");
require("selectize");
require("moment");

// DO NOT ADD daterangepicker in package.json
// b/c this project isn't configured to load css from node_modules
// we copy the js and css into the vendor folder so we can use the css via our regular css pipeline
// require("daterangepicker");

import Chart from 'chart.js/auto';
window.Chart = Chart

require("./regexes");
require("./reporting");
require("./Triggers/ValueComponent");
require("./ProgramGoals/UpdateProgramGoal");
require("./ProgramGoals/ProgramDetailsGoal");
require("./copy-email-to-clipboard");
require("./hide-show");
require("./import-email-from-clipboard");
require("./toggle-regex-notes");
require("./tooltips");
require("./check-all");
require("./emails/show-email-modal");
require("./remote-autocomplete");
require("./components/add-remove-fields");
require("./components/select-all-checkbox");
require("./components/filter-select.js");
require("./companies/dynamic-mailbox-form");
require("./companies/edit-auto-complete");
require("./charts/advanced_purchase");
require("./charts/air_benchmark");
require("./charts/booking_source");
require("./charts/cabin_flown");
require("./charts/car_transportation");
require("./charts/colors");
require("./charts/format_cents");
require("./charts/spend_breakout");
require("./charts/top_hotel_chains");
require("./dashboard/date-range-picker");
require("./dashboard/tile-selector");
require("./matching_lookups/best-in-place");
require("./carrier_fares/effective-date-picker");
require("./tiles/tile-selector");
require("./travelers/map");
require("./matching_lookups/remote-autocomplete");
require("./daterangepicker.js");
