$(document).ready(function() {
  monitorFilterSelect()
})

function monitorFilterSelect() {
  function triggerPageLoad(e) {
      let target = e.target;
      let url = new URL(window.location.href);
      url.searchParams.set(target.name, target.value)
      window.location.href = url.toString();
  }
  let filterSelects = $(".preferences-filter-select-js")
  Array.from(filterSelects).forEach(function (element) {
    element.addEventListener('change', triggerPageLoad);
  });
}
