$(document).ready(function () {
  let $picker = $("#dashboard-date-range-picker");
  if ($picker.length === 0) {
    return;
  }
  let date_format = "MM/DD/YYYY";
  let startDate = $picker.data("start-date");
  var endDate = $picker.data("end-date");
  var maxDate = $picker.data("max-date");

  $picker.daterangepicker(
    {
      timePicker: false,
      linkedCalendars: false,
      showDropdowns: true,
      startDate: startDate,
      endDate: endDate,
      maxDate: maxDate,
      orientation: "auto top",
      locale: {
        format: date_format,
      },
    },
    function (start, end) {
      let url = new URL(window.location.href);
      let startDate = start.format("MM/DD/YYYY");
      let endDate = end.format("MM/DD/YYYY");
      url.searchParams.set("start_date", startDate);
      url.searchParams.set("end_date", endDate);
      url.searchParams.set("page", 1);
      window.location.href = url.href;
    }
  );
});
