/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery'
$(() => $('[data-remote-autocomplete]').each(function(_i, el) {
  const $el = $(el);
  const url = $el.attr('data-remote-autocomplete');
  if ($el.hasClass('selectized') === false) {
    return $el.selectize({
      maxItems: 1,
      load(query, callback) {
        $el.val(query);
        return $.getJSON(url, $el.closest('form').serialize(), function(response) {
          const mapped = response.map(o => ({
            value: o.url,
            text: o.label
          }));
          return callback(mapped);
        });
      },
      onChange(value) {
        if (value.length > 0) {
          return window.location.href = value;
        }
      }
    });
  }
}));
