$(document).ready(function() {
  monitorChildCheckboxes()
  monitorSelectAllCheckbox()
})

function monitorChildCheckboxes() {
  var $selectAllCheckbox = $(".select-all-checkbox").first()
  if ($selectAllCheckbox.length == 0) { return }
  var checkboxNames = $selectAllCheckbox.data("checkbox-names")
  $("input[type='checkbox'][name='" + checkboxNames + "']").change(function() {
    var checkboxCount = countCheckboxes(checkboxNames)
    setSelectAllCheckbox($selectAllCheckbox, checkboxCount)
  })
}

function countCheckboxes(checkboxNames) {
  var checkedCount = 0
  var uncheckedCount = 0
  $("input[type='checkbox'][name='" + checkboxNames + "']").each(function(index, element) {
    if (element.checked) {
      checkedCount += 1
    } else {
      uncheckedCount += 1
    }
  })
  return {
    checkedCount: checkedCount,
    uncheckedCount: uncheckedCount
  }
}

function setSelectAllCheckbox($selectAllCheckbox, checkboxCount) {
  if (checkboxCount.checkedCount == 0) {
    $selectAllCheckbox.prop("checked", false)
    $selectAllCheckbox.prop("indeterminate", false);
  } else if (checkboxCount.uncheckedCount == 0) {
    $selectAllCheckbox.prop("checked", true)
    $selectAllCheckbox.prop("indeterminate", false)
  } else {
    $selectAllCheckbox.prop("indeterminate", true)
  }
}

function monitorSelectAllCheckbox() {
  var $selectAllCheckbox = $(".select-all-checkbox").first()
  if ($selectAllCheckbox.length == 0) { return }
  var checkboxNames = $selectAllCheckbox.data("checkbox-names")
  $selectAllCheckbox.change(function(event) {
    var checked = $selectAllCheckbox.prop("checked")
    setChildCheckboxes(checkboxNames, checked)
  })
}

function setChildCheckboxes(checkboxNames, checked) {
  $("input[type='checkbox'][name='" + checkboxNames + "']").each(function(index, element) {
    element.checked = checked
  })
}
