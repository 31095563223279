$(document).ready(function() {
  $(".email-open-link").click(function() {
    var emailId = $(this).data("email-id")
    rawEmailUrl = "/emails/" + emailId + "/raw"
    var emailIframe = $("#email-iframe");
    emailIframe.prop("src", rawEmailUrl)
    var modal = $("#email-modal");
    modal.modal("show");
  });
});
