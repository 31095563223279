import $ from 'jquery'

$(function () {
  var showHide = function(event) {
    const col = this.closest(".col-md-12");
    const x = col.getElementsByClassName("regex-notes")[0];
    if (x.style.display === "none" || x.style.display === "") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  $(".js-showHideRegexNotes").on("click", showHide);
  $(".add-regex-button").on("click", function(event) {
    event.preventDefault();
    const wrapper = $(this)
      .closest(".control_container")
      .find(".regex_row:last-of-type");
    const newWrapper = wrapper.clone();

    const inputField = newWrapper.find(":input");
    inputField.val("");
    const textareaField = newWrapper.find("textarea");
    textareaField.val("");

    newWrapper.find(".js-showHideRegexNotes").on("click", showHide);;

    const regexesContainer = wrapper.closest(".regexes_container");
    regexesContainer.append(newWrapper);
  });
});
