let Chart = require('chart.js');
import {flatMap, sortBy, reverse} from "lodash";


var cabinFlown = {
  run: function () {
    this.fetchData();
  },

  fetchData: function () {
    var companyId = $("#cabin-flown-pie-chart").data('company-id');
    var startDate = $("#cabin-flown-pie-chart").data('start-date');
    var endDate = $("#cabin-flown-pie-chart").data('end-date');

    var currentUrl = new URL(window.location.href)
    var cabinFlownUrl = new URL(
      window.location.protocol + "//" +
      window.location.host +
      "/tiles/cabin_type_report.json")

    this.copySearchParams(currentUrl, cabinFlownUrl)

    cabinFlownUrl.searchParams.set("company_id", companyId)
    cabinFlownUrl.searchParams.set("start_date", startDate)
    cabinFlownUrl.searchParams.set("end_date", endDate)

    if (companyId) {
      $.ajax({
        url: cabinFlownUrl.href
      }).done((data) => {
        if (this.dataIsEmpty(data)) {
          this.renderEmptyState();
        } else {
          this.buildChart(this.formatData(data));
        }
      }).fail(function(err) {
        console.log(err);
      });
    }
  },

  copySearchParams: function (sourceUrl, destUrl) {
    for(var pair of sourceUrl.searchParams.entries()) {
      destUrl.searchParams.set(pair[0], pair[1])
    }
  },

  dataIsEmpty: function (data) {
    return (data.report.length == 0);
  },

  renderEmptyState: function () {
    $("#cabin-flown-pie-chart").append("\
    <div class='tile-empty-state'>\
      <div>\
        <i class='fas fa-dollar-sign mr1 tile-empty-state-icon'></i>\
      </div>\
      <div>\
        No cabin types matched the applied filter\
      </div>\
    </div>\
    ");
  },

  buildOptions: function () {
    return {
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false //this will remove only the label
          }
        }],
        yAxes: [{
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            beginAtZero: true
          }
        }],
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
              usePointStyle: true,
              generateLabels: function(chart){
              var dataset = chart.data.datasets[0];
              var arcOpts = chart.options.elements.arc;

              //iterate the labels to return legend item interface
              return chart.data.labels.map(function(label, i) {
                  return {
                      text: label,
                      fillStyle: Chart.helpers.getValueAtIndexOrDefault(dataset.backgroundColor, i , arcOpts.backgroundColor),
                      strokeStyle: Chart.helpers.getValueAtIndexOrDefault(dataset.backgroundColor, i , arcOpts.backgroundColor),
                      boxWidth: 0.1, //Chart.helpers.getValueAtIndexOrDefault(dataset.borderWidth, i , arcOpts.borderWidth),
                      hidden: isNaN(dataset.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                      index: i
                  };
              });
          }
      }
      },
    }
  },

  formatData: function (data) {
    let report = data.report
    let sortedData = reverse(sortBy(report, ['number_of_segments'])).slice(0, 5)
    sortedData = reverse(sortBy(sortedData, ['cabin_type']))
    let numberOfSegments = flatMap(sortedData, 'number_of_segments')
    let cabin_types = flatMap(sortedData, 'cabin_type')
    let chartData = {
      datasets: [{
        data: numberOfSegments,
        backgroundColor: [
          window.chartColors.green,
          window.chartColors.blue,
          window.chartColors.red,
          window.chartColors.orange,
        ]
      }],
      labels: cabin_types
    };
    return chartData;
  },

  buildChart: function (data) {
    $("#cabin-flown-pie-chart").append("<canvas></canvas>");
    let canvas = $("#cabin-flown-pie-chart canvas")[0]
    var ctx = canvas.getContext('2d');
    var chart = new Chart(ctx, {
      type: 'pie',
      data: data,
      options: this.buildOptions()
    });
    canvas.onclick = function (evt) {
          let activePoints = chart.getElementsAtEvent(evt);
          if (activePoints[0] == undefined)
          {
            return
          }
          let cabinType = activePoints[0]._model.label;
          var companyId = $("#cabin-flown-pie-chart").data('company-id');
          var startDate = $("#cabin-flown-pie-chart").data('start-date');
          var endDate = $("#cabin-flown-pie-chart").data('end-date');
          let cabinPath = `/reports/cabin_types/${cabinType}?canceled=false&company=${companyId}&end_date=${endDate}&start_date=${startDate}`
          window.location = cabinPath;
      }
    chart.canvas.parentNode.style.height = '188px';
  }
}

$("#cabin-flown-pie-chart").ready(function() {
  cabinFlown.run();
})
