let Chart = require('chart.js');
import {reverse, sortBy, flatMap} from "lodash";
var carTransportation = {
  run: function () {
    this.fetchData();
  },

  fetchData: function () {
    var companyId = $("#car-transportation-bar-chart").data('company-id');
    var startDate = $("#car-transportation-bar-chart").data('start-date');
    var endDate = $("#car-transportation-bar-chart").data('end-date');

    var currentUrl = new URL(window.location.href)
    var carTransportationUrl = new URL(
      window.location.protocol + "//" +
      window.location.host +
      "/tiles/transportation_report.json")

    this.copySearchParams(currentUrl, carTransportationUrl)

    carTransportationUrl.searchParams.set("company_id", companyId)
    carTransportationUrl.searchParams.set("start_date", startDate)
    carTransportationUrl.searchParams.set("end_date", endDate)

    if (companyId) {
      $.ajax({
        url: carTransportationUrl.href
      }).done((data) => {
        if (this.dataIsEmpty(data)) {
          this.renderEmptyState();
        } else {
          this.buildChart(this.formatData(data));
        }
      }).fail(function(err) {
        console.log(err);
      });
    }
  },

  copySearchParams: function (sourceUrl, destUrl) {
    for(var pair of sourceUrl.searchParams.entries()) {
      destUrl.searchParams.set(pair[0], pair[1])
    }
  },

  dataIsEmpty: function (data) {
    return (data.report.length == 0);
  },

  renderEmptyState: function () {
    $("#car-transportation-bar-chart").append("\
    <div class='tile-empty-state'>\
      <div>\
        <i class='fas fa-dollar-sign mr1 tile-empty-state-icon'></i>\
      </div>\
      <div>\
        No brands matched the applied filter\
      </div>\
    </div>\
    ");
  },

  buildOptions: function () {
    return {
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false //this will remove only the label
          }
        }],
        yAxes: [{
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            beginAtZero: true
          }
        }],
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
              usePointStyle: true,
              generateLabels: function(chart){
              var dataset = chart.data.datasets[0];
              var arcOpts = chart.options.elements.arc;

              //iterate the labels to return legend item interface
              return chart.data.labels.map(function(label, i) {
                  return {
                      text: label,
                      fillStyle: Chart.helpers.getValueAtIndexOrDefault(dataset.backgroundColor, i , arcOpts.backgroundColor),
                      strokeStyle: Chart.helpers.getValueAtIndexOrDefault(dataset.backgroundColor, i , arcOpts.backgroundColor),
                      boxWidth: 0.1, //Chart.helpers.getValueAtIndexOrDefault(dataset.borderWidth, i , arcOpts.borderWidth),
                      hidden: isNaN(dataset.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                      index: i
                  };
              });
          }
      }
      },
    }
  },

  formatData: function (data) {
    let report = data.report
    let sortedData = _.reverse(_.sortBy(report, ['name'])).slice(0, 5)
    let names = _.flatMap(sortedData, 'name')
    let total_cost_in_cents = _.flatMap(sortedData, 'car_total_cost_in_cents')
    let chartData = {
      datasets: [{
        data: total_cost_in_cents,
        backgroundColor: [
          window.chartColors.green,
          window.chartColors.blue,
          window.chartColors.red,
          window.chartColors.orange,
          window.chartColors.deepRed,
        ]
      }],
      labels: names
    };
    return chartData;
  },

  buildChart: function (data) {
    $("#car-transportation-bar-chart").append("<canvas></canvas>");
    let canvas = $("#car-transportation-bar-chart canvas")[0]
    var ctx = canvas.getContext('2d');
    var chart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: this.buildOptions()
    });
    chart.canvas.parentNode.style.height = '188px';
  }
}

$("#car-transportation-bar-chart").ready(function() {
  carTransportation.run();
})
