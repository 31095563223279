/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery'

$(function() {
  if ($("#report_range").length === 0) { return; }

  const date_time_format = "MM/DD/YYYY";

  const startDate = $("#report_range").data("start-date");
  const endDate = $("#report_range").data("end-date");

  const pickerInput = $("input[name='finders_emails[daterange]']");

  if (startDate && endDate) {
    pickerInput.val(startDate + ' - ' + endDate);
  }

  pickerInput.daterangepicker({
    "timePicker": false,
    "linkedCalendars": false,
    'autoUpdateInput': false,
    'autoApply': false,
    "startDate": startDate,
    "endDate": endDate,
    locale: {
      format: date_time_format
    }
  });

  pickerInput.on('apply.daterangepicker', function(ev, picker) {
    return $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
  });
    
  return $('input[name="datefilter"]').on('cancel.daterangepicker', function(ev, picker) {
    return $(this).val('');
  });
});
