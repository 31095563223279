var airBenchmark = {
  run: function () {
    this.fetchData();
  },

  fetchData: function () {
    var travelId = $("#air-benchmark-chart").data('travel-id');
    if (travelId == null)
    {
      return;
    }
    var benchmarkUrl = new URL(
      window.location.protocol + "//" +
      window.location.host +
      "/reports/benchmarks/air_drilldown.json")

    benchmarkUrl.searchParams.set("travel_id", travelId)

    $.ajax({
      url: benchmarkUrl.href
    }).done((data) => {
      if (this.dataIsEmpty(data)) {
        this.renderEmptyState();
      } else {
        dates = data.suppliers.map(d => d.data);
        dates = [].concat(...dates);
        dates = dates.map( (x) => x.x)
        maxDate = Math.max(...dates)
        minDate = Math.min(...dates)

        minDate = moment(minDate, 'YYYYMM').utcOffset('-0400').valueOf()
        maxDate = moment(maxDate, 'YYYYMM').utcOffset('-0400').valueOf()
        this.buildChart(minDate, maxDate, this.formatDataSets(data));
      }
    }).fail(function(err) {
      console.log(err);
    });
  },

  dataIsEmpty: function (data) {
    return (data.suppliers.length == 0);
  },

  renderEmptyState: function () {
    $("#air-benchmark-chart").append("\
    <div class='tile-empty-state'>\
      <div>\
        <i class='fas fa-dollar-sign mr1 tile-empty-state-icon'></i>\
      </div>\
      <div>\
      Loading...\
      </div>\
    </div>\
    ");
  },

  buildOptions: function (minX, maxX) {
    return {
      maintainAspectRatio: false,
      showLines: true,
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
          type: 'time',
          time: {
            min: minX,
            max: maxX,
          },
          ticks: {
            callback: function(value, index, values) {
              return moment(values[index].value).format("MMM YYYY");
            },
            padding: 10,
            source: 'data'
          },
          distribution: 'linear',
        }],
        yAxes: [{
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            callback: function(value, index, values) {
              return value.formatCents(true);
            },
            fontFamily: "'Roboto Mono', 'monospace'",
            padding: 10
          },
        }],
      },
      legend: {
        display: true
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            var cents = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y
            var date = moment(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].x)
            var currency = cents.formatCents()
            return label + ": " + currency + " - " + date.format("MMM YYYY");
          }
        }
      }
    }
  },

  formatData: function(data, i) {
    color = data.supplier_color
    data = {
      data: data.data.map(function(point) {
        return {
          x: moment(point.x, "YYYYMM").utcOffset('-0400').valueOf(),
          y: point.y
        };
      }),
      label: data.supplier_name,
      pointBorderWidth: '3',
      pointRadius: '3',
      fill: false,
      pointBackgroundColor: color,
      pointBorderColor: color,
      borderColor: color,
      backgroundColor: color
    };

    return data;
  },

  formatDataSets: function (data) {
    let chartData = {
      datasets: data.suppliers.map(this.formatData),
      labels: ["Benchmark", "Month"]
    };
    return chartData;
  },

  buildChart: function (minX, maxX, data) {
    $("#air-benchmark-chart").append("<canvas></canvas>");
    var ctx = $("#air-benchmark-chart canvas")[0].getContext('2d');
    var chart = new Chart(ctx, {
      type: 'scatter',
      data: data,
      options: this.buildOptions(minX, maxX)
    });
    chart.canvas.parentNode.style.height = '500px';
  }
}

$("#air-benchmark-chart").ready(function() {
  airBenchmark.run();
})
