import $ from "jquery";
$(function() {
  $(document).ready(function() {
    $("#copy-email-to-clipboard").click(function() {
      let scrubbed_email_json = $(this).attr("data-scrubbed-email-json");
      copyToClipboard(scrubbed_email_json);
      $(this).text("Copied!");
    });
  });

  function copyToClipboard(text) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(text).select();
    document.execCommand("copy");
    $temp.remove();
  }
});
