import $ from 'jquery'
$(function () {
  $("button#hide-show-btn").click(function() {
    $(".hide-show").toggle();
  });
});

$(function() {
  $("button.hide-show-btn").click(function() {
    var targetClass = $(this).data('target')
    var newText = $(this).text() === "Show" ? "Hide" : "Show"
    $(this).text(newText)
    $(".hide-show." + targetClass).toggle();
  });
});
