var spendBreakout = {
  run: function () {
    this.fetchData();
  },

  fetchData: function () {
    var companyId = $("#spend-breakout-tile").data('company-id');
    var startDate = $("#spend-breakout-tile").data('start-date');
    var endDate = $("#spend-breakout-tile").data('end-date');

    var currentUrl = new URL(window.location.href)
    var spendBreakoutUrl = new URL(
      window.location.protocol + "//" +
      window.location.host +
      "/reports/spend_breakout.json")

    this.copySearchParams(currentUrl, spendBreakoutUrl)

    spendBreakoutUrl.searchParams.set("company_id", companyId)
    spendBreakoutUrl.searchParams.set("start_date", startDate)
    spendBreakoutUrl.searchParams.set("end_date", endDate)

    if (companyId) {
      $.ajax({
        url: spendBreakoutUrl.href
      }).done((data) => {
        if (this.dataIsEmpty(data)) {
          this.renderEmptyState();
        } else {
          this.buildChart(this.formatData(data));
        }
      }).fail(function(err) {
        console.log(err);
      });
    }
  },

  copySearchParams: function (sourceUrl, destUrl) {
    for(var pair of sourceUrl.searchParams.entries()) {
      destUrl.searchParams.set(pair[0], pair[1])
    }
  },

  dataIsEmpty: function (data) {
    return (data.air_segment_spend_in_cents == 0 &&
            data.car_rental_spend_in_cents == 0 &&
            data.shared_economy_spend_in_cents == 0 &&
            data.train_economy_spend_in_cents == 0 &&
            data.hotel_room_spend_in_cents == 0);
  },

  renderEmptyState: function () {
    $("#spend-breakout-tile").append("\
    <div class='tile-empty-state'>\
      <div>\
        <i class='fas fa-dollar-sign mr1 tile-empty-state-icon'></i>\
      </div>\
      <div>\
      No spending matched the applied filter\
      </div>\
    </div>\
    ");
  },

  buildOptions: function () {
    return {
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: false
          },
          ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
            }
        }
        },
        x: {
          beginAtZero: true,
          grid: {
            display: false
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                  label +=  ' ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                return label;
            }
          }
      },
        legend: {
          display: false
        }
      }
    }
  },

  formatData: function (data) {
    let chartData = {
      datasets: [{
        data: [
          data.air_segment_spend_in_cents,
          data.train_segment_spend_in_cents,
          data.car_rental_spend_in_cents,
          data.hotel_room_spend_in_cents,
          data.shared_economy_spend_in_cents,
        ],
        backgroundColor: [
          window.chartColors.green,
          window.chartColors.orange,
          window.chartColors.blue,
          window.chartColors.red,
          window.chartColors.deepRed,
        ],
      }],
      labels: ["Air" + "  (" + data.air_travel_average + "%)", 
        "Train" + "  (" + data.train_travel_average + "%)", 
        "Car" + "  (" + data.car_rental_travel_average + "%)", 
        "Hotel" + "  (" + data.hotel_room_travel_average + "%)", 
        "Shared Econ" + "  (" + data.shared_economy_travel_average + "%)"]
    };
    return chartData;
  },

  buildChart: function (data) {
    $("#spend-breakout-tile").append("<canvas></canvas>");
    var ctx = $("#spend-breakout-tile canvas")[0].getContext('2d');
    var chart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: this.buildOptions()
    });
    chart.canvas.parentNode.style.height = '188px';
  }
}

$("#spend-breakout-tile").ready(function() {
  spendBreakout.run();
})
