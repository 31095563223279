$(document).ready(function() {
  $(".single-date-picker").each(function(index, singleDatePicker) {
    $singleDatePicker = $(singleDatePicker)

    $singleDatePicker.daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      autoUpdateInput: false,
    });

    $singleDatePicker.on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('MM/DD/YYYY'))
    });

    $singleDatePicker.on('hide.daterangepicker', function(ev, picker) {
      $(this).val('')
    });
  })
})
