let Chart = require('chart.js');
import {flatMap} from "lodash";

var advancedPurchase = {
  run: function () {
    this.fetchData();
  },

  fetchData: function () {
    var companyId = $("#advanced-purchase-bar-chart").data('company-id');
    var startDate = $("#advanced-purchase-bar-chart").data('start-date');
    var endDate = $("#advanced-purchase-bar-chart").data('end-date');

    var currentUrl = new URL(window.location.href)
    var url = new URL(
      window.location.protocol + "//" +
      window.location.host +
      "/tiles/advanced_purchase_report.json")

    this.copySearchParams(currentUrl, url)

    url.searchParams.set("company_id", companyId)
    url.searchParams.set("start_date", startDate)
    url.searchParams.set("end_date", endDate)

    if (companyId) {
      $.ajax({
        url: url.href
      }).done((data) => {
        if (this.dataIsEmpty(data)) {
          this.renderEmptyState();
        } else {
          this.buildChart(this.formatData(data));
        }
      }).fail(function(err) {
        console.log(err);
      });
    }
  },

  copySearchParams: function (sourceUrl, destUrl) {
    for(var pair of sourceUrl.searchParams.entries()) {
      destUrl.searchParams.set(pair[0], pair[1])
    }
  },

  dataIsEmpty: function (data) {
    return (data.report.length == 0);
  },

  renderEmptyState: function () {
    $("#advanced-purchase-bar-chart").append("\
    <div class='tile-empty-state'>\
      <div>\
        <i class='fas fa-dollar-sign mr1 tile-empty-state-icon'></i>\
      </div>\
      <div>\
        No advanced purchase matched the applied filter\
      </div>\
    </div>\
    ");
  },

  buildOptions: function () {
    return {
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false //this will remove only the label
          }
        }],
        yAxes: [{
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            beginAtZero: true
          }
        }],
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
              usePointStyle: true,
              generateLabels: function(chart){
              var dataset = chart.data.datasets[0];
              var arcOpts = chart.options.elements.arc;

              //iterate the labels to return legend item interface
              return chart.data.labels.map(function(label, i) {
                  return {
                      text: label,
                      fillStyle: Chart.helpers.getValueAtIndexOrDefault(dataset.backgroundColor, i , arcOpts.backgroundColor),
                      strokeStyle: Chart.helpers.getValueAtIndexOrDefault(dataset.backgroundColor, i , arcOpts.backgroundColor),
                      boxWidth: 0.1, //Chart.helpers.getValueAtIndexOrDefault(dataset.borderWidth, i , arcOpts.borderWidth),
                      hidden: isNaN(dataset.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                      index: i
                  };
              });
          }
      }
      },
    }
  },

  formatData: function (data) {
    let report = data.report
    let sortedData = ["0-6","7-13","14-20","21+"].map( range => report.find(function(element) { return element.days_in_advance == range; })).filter(Boolean);
    let number_of_segments = flatMap(sortedData, 'number_of_trips')
    let labels = flatMap(sortedData, 'days_in_advance')
    let chartData = {
      datasets: [{
        data: number_of_segments,
        backgroundColor: [
          window.chartColors.green,
          window.chartColors.blue,
          window.chartColors.red,
          window.chartColors.orange,
        ]
      }],
      labels: labels
    };
    return chartData;
  },

  buildChart: function (data) {
    $("#advanced-purchase-bar-chart").append("<canvas></canvas>");
    let canvas = $("#advanced-purchase-bar-chart canvas")[0]
    var ctx = canvas.getContext('2d');
    var chart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: this.buildOptions()
    });
    canvas.onclick = function (evt) {
          let activePoints = chart.getElementsAtEvent(evt);
          if (activePoints[0] == undefined)
          {
            return
          }
          let range = activePoints[0]._model.label;
          var companyId = $("#advanced-purchase-bar-chart").data('company-id');
          var startDate = $("#advanced-purchase-bar-chart").data('start-date');
          var endDate = $("#advanced-purchase-bar-chart").data('end-date');
          let rangePath = `/reports/advanced_purchase/${range}?canceled=false&company=${companyId}&end_date=${endDate}&start_date=${startDate}`
          window.location = rangePath;
      }
    chart.canvas.parentNode.style.height = '188px';
  }
}

$("#advanced-purchase-bar-chart").ready(function() {
  advancedPurchase.run();
})
