let Chart = require('chart.js');
import {reverse, sortBy, flatMap} from "lodash";

var topHotelChains = {
  run: function() {
    this.fetchData();
  },

  fetchData: function() {
    var companyId = $("#top-hotel-chains-bar-chart").data("company-id");
    var startDate = $("#top-hotel-chains-bar-chart").data("start-date");
    var endDate = $("#top-hotel-chains-bar-chart").data("end-date");

    var currentUrl = new URL(window.location.href);
    var url = new URL(
      window.location.protocol +
        "//" +
        window.location.host +
        "/tiles/top_hotel_brands_report.json"
    );

    this.copySearchParams(currentUrl, url);

    url.searchParams.set("company_id", companyId);
    url.searchParams.set("start_date", startDate);
    url.searchParams.set("end_date", endDate);

    if (companyId) {
      $.ajax({
        url: url.href
      })
        .done(data => {
          if (this.dataIsEmpty(data)) {
            this.renderEmptyState();
          } else {
            this.buildChart(this.formatData(data));
          }
        })
        .fail(function(err) {
          console.log(err);
        });
    }
  },

  copySearchParams: function(sourceUrl, destUrl) {
    for (var pair of sourceUrl.searchParams.entries()) {
      destUrl.searchParams.set(pair[0], pair[1]);
    }
  },

  dataIsEmpty: function(data) {
    return data.report.length == 0;
  },

  renderEmptyState: function() {
    $("#top-hotel-chains-bar-chart").append(
      "\
    <div class='tile-empty-state'>\
      <div>\
        <i class='fas fa-dollar-sign mr1 tile-empty-state-icon'></i>\
      </div>\
      <div>\
        No hotel chains matched the applied filter\
      </div>\
    </div>\
    "
    );
  },

  buildOptions: function() {
    return {
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false //this will remove only the label
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false,
              beginAtZero: true
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            var label =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return label.formatCents();
          }
        }
      },
      legend: {
        display: true,
        position: "right",
        labels: {
          usePointStyle: true,
          generateLabels: function(chart) {
            var dataset = chart.data.datasets[0];
            var arcOpts = chart.options.elements.arc;

            //iterate the labels to return legend item interface
            return chart.data.labels.map(function(label, i) {
              return {
                text: label,
                fillStyle: Chart.helpers.getValueAtIndexOrDefault(
                  dataset.backgroundColor,
                  i,
                  arcOpts.backgroundColor
                ),
                strokeStyle: Chart.helpers.getValueAtIndexOrDefault(
                  dataset.backgroundColor,
                  i,
                  arcOpts.backgroundColor
                ),
                boxWidth: 0.1, //Chart.helpers.getValueAtIndexOrDefault(dataset.borderWidth, i , arcOpts.borderWidth),
                hidden:
                  isNaN(dataset.data[i]) ||
                  chart.getDatasetMeta(0).data[i].hidden,
                index: i
              };
            });
          }
        }
      }
    };
  },

  formatData: function(data) {
    let report = data.report;
    let sortedData = _.reverse(_.sortBy(report, ["hotel_total_cost_in_cents"])).slice(
      0,
      5
    );
    sortedData = _.sortBy(sortedData, ["hotel_brand"]);
    let totalCostInCents = _.flatMap(sortedData, "hotel_total_cost_in_cents");
    let hotelBrands = _.flatMap(sortedData, "hotel_brand");
    let chartData = {
      datasets: [
        {
          data: totalCostInCents,
          backgroundColor: [
            window.chartColors.green,
            window.chartColors.blue,
            window.chartColors.red,
            window.chartColors.orange,
            window.chartColors.deepRed
          ]
        }
      ],
      labels: hotelBrands
    };
    return chartData;
  },

  buildChart: function(data) {
    $("#top-hotel-chains-bar-chart").append("<canvas></canvas>");
    let canvas = $("#top-hotel-chains-bar-chart canvas")[0];
    var ctx = canvas.getContext("2d");
    var chart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: this.buildOptions()
    });
    chart.canvas.parentNode.style.height = "188px";
  }
};

$("#top-hotel-chains-bar-chart").ready(function() {
  topHotelChains.run();
});
